<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="project__question_response__container">
                  <p class="project__question_response__container-title">
                    プロジェクトの募集を停止しますか？
                  </p>
                  <p class="project__question_response__container-content">
                    再開の場合は再度プロジェクトを編集・申請してください。
                  </p>
                  <div class="project__question_response__container-button">
                    <button
                      @click="$emit('close')"
                      type="button"
                      class="btn-light-gray btn-md shadow"
                    >キャンセル</button>
                    <button
                     @click="$emit('accept')"
                      class="btn-blue btn-md shadow"
                    >
                      OK
                    </button>
                  </div>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script lang="ts" src="@/presentation/components/modals/project-confirm-stop/ProjectConfirmStop.ts" />
<link type="scss" scoped src="@/presentation/components/modals/project-confirm-stop/ProjectConfirmStop.scss" />

<template id="modal-template">
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-body">
            <slot name="body">
              <div class="forgotPassword__container" v-if="props.applicant">
                <div class="forgotPassword__container-title">
                    {{props.applicant.team_name ? 'チームメンバーを一括評価' : 'ユーザーを評価'}}
                    </div>
                <p class="forgotPassword__container-sub_title">
                  プロジェクト完了後、
                  <br>
                  双方が評価するとプロジェクト終了となり、委託料の支払いが実行されます
                  <br />
                </p>
                <form @submit="submit" class="horizontal">
                  <div class="form-ctrl">
                    <label for class="text">
                        <span v-if="props.applicant.team_name">チーム名</span>
                        <span v-else> ユーザー名</span> </label>
                    <div class="form-group-row">
                      <div class="form-group">
                        <span class="name">{{props.applicant.team_name ? props.applicant.team_name : props.applicant.user_nickname}}</span>
                      </div>

                    </div>
                  </div>
                  <div class="form-ctrl">
                    <label for class="text">評価点数</label>
                    <div class="form-group-row">
                      <div class="form-group">
                        <select
                          v-model="values.rating"
                          :class="{ invalid: !values.rating }"
                          class="select"
                        >
                          <option value="" selected>評価点数を選択</option>
                        <option
                            v-for="i in 5"
                            :key="i"
                            :value="i"
                            data-icon="<i class='fas fa-star'></i>"
                        >{{i}}</option>
                        </select>
                        <ErrorMessage
                          v-if="errors.rating"
                          :message="errors.rating"
                        ></ErrorMessage>
                      </div>

                    </div>
                  </div>
                  <div class="form-ctrl">
                    <label for class="text">評価内容</label>
                    <div class="form-group-row">
                      <div class="form-group">
                      <Textarea v-model="values.rating_comment"
                                placeholder="プロジェクトを進める上で、このチームの良かったところや特に良い動きをしたユーザーなどを記載してください。&#13;※この内容は公開されます。"
                                maxlength="1000"/>
                        <ErrorMessage
                            v-if="errors.rating_comment"
                            :message="errors.rating_comment"
                        ></ErrorMessage>
                      </div>

                    </div>
                  </div>
                  <div class="forgotPassword__container-button">
                    <button
                      type="button"
                      class="btn-lg btn-light-gray shadow"
                      @click="$emit('close')"
                    >キャンセル</button>
                    <button
                      type="submit"
                      :disabled="state.isSubmitting"
                      class="btn-lg btn-blue shadow"
                    >
                      <i v-if="state.isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                    評価を実施</button>
                  </div>
                </form>
              </div>

              <div class="forgotPassword__container" v-if="props.mode === 'rateOrganize'">
                <div class="forgotPassword__container-title">
                    完了報告
                </div>
                <p class="forgotPassword__container-sub_title">
                  このプロジェクトの完了報告とクライアント企業への評価を行います。
                  <br>
                  評価が終了したらプロジェクトが終了し、報酬が支払われます。
                  <br />
                </p>
                <form @submit="submitOrganize" class="horizontal">
                  <div class="form-ctrl">
                    <label for class="text">
                        企業名
                    </label>
                    <div class="form-group-row">
                      <div class="form-group">
                        <span class="name">{{ state.organizationInfo.name }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="form-ctrl">
                    <label for class="text">評価点数</label>
                    <div class="form-group-row">
                      <div class="form-group">
                        <select
                          v-model="values.rating"
                          :class="{ invalid: !values.rating }"
                          class="select"
                        >
                          <option value="" selected>評価点数を選択</option>
                        <option
                            v-for="i in 5"
                            :key="i"
                            :value="i"
                            data-icon="<i class='fas fa-star'></i>"
                        >{{i}}</option>
                        </select>
                        <ErrorMessage
                          v-if="errors.rating"
                          :message="errors.rating"
                        ></ErrorMessage>
                      </div>

                    </div>
                  </div>
                  <div class="form-ctrl">
                    <label for class="text">評価内容</label>
                    <div class="form-group-row">
                      <div class="form-group">
                      <Textarea v-model="values.rating_comment" maxlength="1000" placeholder="プロジェクトを進める上で、クライアントの良かったところ等を
記載してください。※この内容は公開されます" />
                        <ErrorMessage
                          v-if="errors.rating_comment"
                          :message="errors.rating_comment"
                        ></ErrorMessage>
                      </div>

                    </div>
                  </div>
                  <div class="forgotPassword__container-button">
                    <button
                      type="button"
                      class="btn-lg btn-light-gray shadow"
                      @click="$emit('close')"
                    >キャンセル</button>
                    <button
                      type="submit"
                      :disabled="state.isSubmitting"
                      class="btn-lg btn-blue shadow"
                    >
                      <i v-if="state.isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                    評価を実施</button>
                  </div>
                </form>
              </div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts" src="@/presentation/components/modals/rating/Rating.ts" />
<link type="scss" scoped src="@/presentation/components/modals/rating/Rating.scss" />

import { __awaiter, __generator } from "tslib";
import { reactive, defineComponent, onMounted, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import companyLogo from "@/assets/images/layout/company_logo.png";
import ProjectDetailPreload from "@/presentation/components/preloader/project-detail-preload/ProjectDetailPreload.vue";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import ProjectInteractor from "@/domain/usecases/ProjectInteractor";
import RoutePath from '@/commons/RoutePath';
import RatingPopup from "@/presentation/components/modals/rating/Rating.vue";
import EndRecruiting from "@/presentation/components/modals/project-confirm-end-recruiting/ProjectConfirmEndRecruiting.vue";
import SMSPopup from "@/presentation/components/modals/sms/SMS.vue";
import OTPPopup from "@/presentation/components/modals/otp/Otp.vue";
import ProjectConfirmCancelApplyPopup from "@/presentation/components/modals/project-confirm-cancel-apply/ProjectConfirmCancelApply.vue";
import ProjectConfirmStop from "@/presentation/components/modals/project-confirm-stop/ProjectConfirmStop.vue";
import ProjectConfirmReopen from "@/presentation/components/modals/project-confirm-reopen/ProjectConfirmReopen.vue";
import Alert from "@/presentation/components/modals/alert/Alert.vue";
import CommonService from '@/services/CommonService';
import TransformUtil from '@/commons/TransformUtil';
import { getCurrentOrganizationId } from "@/commons/UserStatusUtis";
export default defineComponent({
    name: "ProjectDetailInformation",
    components: {
        Textarea: Textarea,
        ProjectDetailPreload: ProjectDetailPreload,
        RatingPopup: RatingPopup,
        SMSPopup: SMSPopup,
        OTPPopup: OTPPopup,
        ProjectConfirmCancelApplyPopup: ProjectConfirmCancelApplyPopup,
        EndRecruiting: EndRecruiting,
        Alert: Alert,
        ProjectConfirmStop: ProjectConfirmStop,
        ProjectConfirmReopen: ProjectConfirmReopen,
    },
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore();
        var router = useRouter();
        var route = useRoute();
        var isSubmitting = false;
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var projectInteractor = DIContainer.instance.get(ProjectInteractor);
        var state = reactive({
            projectStatus: "",
            projectInfo: {},
            projectId: Number(route.params.id),
            preload: true,
            showConfirmComplete: false,
            isSubmittingStop: false,
            btnIsSubmitting: 'stop',
            showConfirmWorkingComlete: false,
            userContractors: [],
            teamContractors: [],
            showRatingApplicant: false,
            currentRatingApplicant: null,
            isUser: false,
            isOwner: false,
            ratingMode: "rateUser",
            applicantStatus: 'NOTAPPLY',
            isUserSelected: false,
            showSMSPopup: false,
            showOtpPopup: false,
            showProjectCompletedPopup: false,
            projectOwnerAccountId: null,
            showProjectConfirmCancelApplyPopup: false,
            showProjectEndReCruitingPopup: false,
            showAlertPopup: false,
            alertContent: {
                title: "",
                content: ""
            },
            showConfirmStop: false,
            showConfirmReopen: false,
            showProjectUnApprovedAlert: false,
            showProjectReleasedAlert: false,
            isDisabled: false
        });
        var workingLabelVisible = computed(function () {
            var projectInfo = state.projectInfo;
            if ((projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.progress_status) === 'working') {
                // 自社
                if (CommonService.isOrganization() && (projectInfo === null || projectInfo === void 0 ? void 0 : projectInfo.project_owner_organization_id) === getCurrentOrganizationId())
                    return true;
                // アサインされたチームメンバー
                else if (CommonService.isUser() &&
                    (store.state.projectContractorStatus === 'LEADER' || store.state.projectContractorStatus === 'MEMBER'))
                    return true;
            }
            return false;
        });
        var isDisabled = computed(function () { return store.state.isDisabled; });
        function getContractor() {
            organizationProjectInteractor.getContractor(state.projectId).then(function (result) {
                if (!result.data || result.data.length == 0) {
                    state.preload = false;
                    return;
                }
                result.data.forEach(function (contractor) {
                    contractor.user_rating_count = TransformUtil.formatNumber(contractor.user_rating_count);
                    contractor.user_rating_ave = TransformUtil.pointRound(contractor.user_rating_ave);
                    if (contractor.members && contractor.members.length) {
                        var leader = contractor.members.filter(function (o) { return o.is_leader == 1; })[0];
                        contractor.user_rating_ave = TransformUtil.pointRound(leader.user_rating_ave);
                        contractor.user_rating_count = TransformUtil.formatNumber(leader.user_rating_count);
                        contractor.user_nickname = leader.user_nickname;
                        contractor.user_account_id = leader.user_account_id;
                        contractor.members.forEach(function (m) {
                            m.user_rating_count = TransformUtil.formatNumber(m.user_rating_count);
                            m.user_rating_ave = TransformUtil.pointRound(m.user_rating_ave);
                        });
                    }
                });
                state.userContractors = result.data.filter(function (o) { return !o.team_name; });
                state.teamContractors = result.data.filter(function (o) { return o.team_name; });
                state.preload = false;
            }).catch(function (error) {
                state.preload = false;
            });
        }
        function checkStatusApplicant() {
            projectInteractor.getCurrentUserProjectApplyInfo(state.projectId)
                .then(function (result) {
                state.applicantStatus = result.data.applicant_status;
            })
                .catch(function (error) {
            });
        }
        onMounted(function () {
            state.isUser = CommonService.isUser() ? true : false;
            if (state.isUser) {
                checkStatusApplicant();
            }
            getProjectDetail(state.projectId);
            store.dispatch('checkActiveTeamSelect');
        });
        function confirmStop() {
            state.showConfirmStop = true;
        }
        function confirmReopen() {
            state.showConfirmReopen = true;
        }
        function stopProject() {
            state.btnIsSubmitting = 'stop';
            state.isSubmittingStop = true;
            var project = {
                project_id: state.projectId,
                progress_status: "STOP"
            };
            organizationProjectInteractor.updateOrganizationProject(state.projectId, project).then(function (result) {
                state.isSubmittingStop = false;
                state.projectInfo.progress_status = "stop";
                state.alertContent.content = "プロジェクトの募集を停止しました";
                state.showAlertPopup = true;
                state.showConfirmStop = false;
                reloadProject();
            }).catch(function (error) {
                state.isSubmittingStop = false;
                state.showConfirmStop = false;
            });
        }
        function reopenProject() {
            state.isSubmittingStop = true;
            state.btnIsSubmitting = 'reopenProject';
            var project = {
                project_id: state.projectId,
                progress_status: "RELEASE"
            };
            organizationProjectInteractor.updateOrganizationProject(state.projectId, project).then(function (result) {
                state.isSubmittingStop = false;
                state.alertContent.content = "プロジェクトの募集を再開しました";
                state.showConfirmReopen = false;
                state.showAlertPopup = true;
                getProjectDetail(state.projectId);
            }).catch(function (error) {
                state.isSubmittingStop = false;
                state.showConfirmReopen = false;
            });
        }
        function editProject() {
            store.dispatch('checkActiveTeamSelect').then(function () {
                if (!isDisabled.value) {
                    router.push("/project/" + state.projectId + "/edit");
                }
            });
        }
        function endProject() {
            state.showConfirmComplete = true;
        }
        function showMatches() {
            router.push("/project/" + state.projectId + "/matches");
        }
        function endRecruiting() {
            state.isSubmittingStop = true;
            state.btnIsSubmitting = 'endRecruiting';
            state.showProjectEndReCruitingPopup = true;
            return;
        }
        function rateApplicant(applicant) {
            state.ratingMode = "rateUser";
            state.showRatingApplicant = true;
            state.currentRatingApplicant = applicant;
        }
        // user action
        function applyProject() {
            projectInteractor.checkProjectApplyRequirement()
                .then(function (result) {
                if (!result.data) {
                    return;
                }
                if (result.data.result === 'NG_SMS') {
                    state.showSMSPopup = true;
                }
                else if (result.data.result === 'OK') {
                    router.push("/project/" + state.projectId + "/apply/info");
                }
            })
                .catch(function (error) {
            });
        }
        function confirmSMS() {
            // router.push("/project/" + state.projectId + "/apply/info");
        }
        function cancelApplyProject() {
            projectInteractor.cancelProjectApplyCheck(state.projectId)
                .then(function (result) {
                state.applicantStatus = 'NOTAPPLY';
                state.showProjectConfirmCancelApplyPopup = false;
                state.alertContent.content = "応募をキャンセルしました";
                state.showAlertPopup = true;
            })
                .catch(function (error) {
            });
        }
        function getProjectDetail(projectId) {
            organizationProjectInteractor.getOrganizationProjectById(projectId).then(function (result) {
                if (!result.data) {
                    state.preload = false;
                    return;
                }
                result.data.working_period_start_at = TransformUtil.convertDatetime(result.data.working_period_start_at);
                result.data.working_period_end_at = TransformUtil.convertDatetime(result.data.working_period_end_at);
                result.data.skillsConvert = "";
                result.data.skills.forEach(function (skill, index) {
                    if (index == 0) {
                        result.data.skillsConvert += skill.name;
                    }
                    else {
                        result.data.skillsConvert += "\u3001" + skill.name;
                    }
                    result.data.skills[index] = skill.id;
                });
                result.data.desired_reward_min_convert = TransformUtil.formatNumber(result.data.desired_reward_min);
                result.data.desired_reward_max_convert = TransformUtil.formatNumber(result.data.desired_reward_max);
                result.data.languageConcat = "";
                result.data.languages.map(function (language, index) {
                    if (index == 0) {
                        result.data.languageConcat += CommonService.mapLanguageName(language);
                    }
                    else {
                        result.data.languageConcat += "\u3001" + CommonService.mapLanguageName(language);
                    }
                });
                store.commit('setProjectInfo', result.data);
                state.projectOwnerAccountId = result.data.project_owner_account_id;
                state.projectInfo = result.data;
                state.preload = false;
                // 登録申請または一時保存直後の完了モーダルを表示
                if (CommonService.isOrganization()) {
                    if (store.state.projectUnApproved === result.data.project_id) {
                        state.showProjectUnApprovedAlert = true;
                        store.commit('setProjectUnApproved', null);
                    }
                    else if (store.state.projectTempSaved === result.data.project_id) {
                        state.alertContent = {
                            title: "一時保存完了",
                            content: "プロジェクトの一時保存が完了しました。"
                        };
                        state.showAlertPopup = true;
                        store.commit('setProjectTempSaved', null);
                    }
                }
                if (state.projectInfo.progress_status == "completed" || state.projectInfo.progress_status == "working") {
                    getContractor();
                }
                else {
                    state.preload = false;
                }
                state.isOwner = state.projectInfo.project_owner_organization_id === getCurrentOrganizationId();
            }).catch(function (error) {
                state.preload = false;
            });
        }
        function gotoAchievement(account_id) {
            router.push(RoutePath.USER_PROFILE + "/" + account_id + "/achievement");
        }
        function gotoUserProfile(account_id) {
            router.push(RoutePath.USER_PROFILE + "/" + account_id);
        }
        function closeRating() {
            state.showRatingApplicant = false,
                getProjectDetail(state.projectId);
        }
        function reloadProject() {
            getProjectDetail(state.projectId);
        }
        function onCompletedEndRecruiting(close) {
            if (close === void 0) { close = false; }
            return __awaiter(this, void 0, void 0, function () {
                var _this = this;
                return __generator(this, function (_a) {
                    if (close) {
                        state.isSubmittingStop = false;
                        state.showProjectEndReCruitingPopup = false;
                    }
                    else {
                        state.showProjectEndReCruitingPopup = false;
                        try {
                            setTimeout(function () { return __awaiter(_this, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, reloadProject()];
                                        case 1:
                                            _a.sent();
                                            state.showProjectEndReCruitingPopup = false;
                                            state.isSubmittingStop = false;
                                            state.alertContent.content = "プロジェクトの募集を終了しました";
                                            state.showAlertPopup = true;
                                            return [2 /*return*/];
                                    }
                                });
                            }); }, 200);
                        }
                        catch (error) {
                            state.showProjectEndReCruitingPopup = false;
                            state.isSubmittingStop = false;
                        }
                    }
                    return [2 /*return*/];
                });
            });
        }
        function onCompletedRatingOrganization() {
            checkStatusApplicant();
            state.showAlertPopup = true;
            state.alertContent = {
                title: "評価完了",
                content: "クライアント企業の評価がおわりました。<br>これでプロジェクトは終了です。"
            };
        }
        function closeConfirmStop() {
            state.showConfirmStop = false;
        }
        return {
            state: state,
            companyLogo: companyLogo,
            isSubmitting: isSubmitting,
            stopProject: stopProject,
            endRecruiting: endRecruiting,
            editProject: editProject,
            endProject: endProject,
            reopenProject: reopenProject,
            rateApplicant: rateApplicant,
            getContractor: getContractor,
            applyProject: applyProject,
            cancelApplyProject: cancelApplyProject,
            confirmSMS: confirmSMS,
            gotoAchievement: gotoAchievement,
            gotoUserProfile: gotoUserProfile,
            closeRating: closeRating,
            reloadProject: reloadProject,
            onCompletedRatingOrganization: onCompletedRatingOrganization,
            onCompletedEndRecruiting: onCompletedEndRecruiting,
            closeConfirmStop: closeConfirmStop,
            confirmStop: confirmStop,
            confirmReopen: confirmReopen,
            showMatches: showMatches,
            workingLabelVisible: workingLabelVisible,
            isDisabled: isDisabled
        };
    }
});

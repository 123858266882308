<template>
  <div>
    <ProjectDetailPreload v-if="state.preload" />
    <div v-if="!state.preload">
      <div class="project_detail__container__tab__content">
        <div class="project_detail__container__tab__content__working-label" v-if="workingLabelVisible">
          <div class="project_detail__container__tab__content__working-label__item">
            実施中
          </div>
        </div>
        <!-- status == rejected -->
        <div class="project_detail__container__tab__content_detail">
          <form class="project__rejected" v-if="state.projectInfo.progress_status == 'rejected'">
            <div class="form-edit-group">
              <div class="form-group">
                <h4>差戻し理由</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.rejected_reason}}</pre>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="project_detail__container__tab__content_detail">
          <form class="project__detail" v-if="state.projectInfo">
            <div class="form-edit-group">
              <div class="form-ctrl">
                <label class="col-left">相談ID</label>
                <div class="text-view">
                  <span>{{ state.projectInfo.code }}</span>
                </div>
              </div>
            </div>
            <div class="form-edit-group">
              <div class="form-ctrl">
                <label class="col-left">相談パターン</label>
                <div class="text-view">
                  <span>{{ state.projectInfo.category.name }}</span>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="project_detail__container__wrapper">
        <div class="project_detail__container__wrapper__right">
          <div class="project_detail__container__wrapper__right__content" v-if="state.projectInfo">
            <form>
              <div class="form-group">
                <h4>企業名</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.organization_name}}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>業種</h4>
                <div class="form-ctrl">
                  <template v-for="industry in state.projectInfo.industries">
                    <pre class="text-view">{{ industry }}   </pre>
                  </template>
                  <pre class="text-view">{{ state.projectInfo.industry_optional }}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>所在地</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.prefecture}}   {{ state.projectInfo.city }} </pre>
                </div>
              </div>
              <div class="form-group">
                <h4>事業概要</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.organization_profile_description}}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>DXへの取り組み状況</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.organization_profile_ai_description}}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>相談概要</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.description}}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>データタイプ</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{ state.projectInfo.data_types.map(item => item.name).join("\n") }}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>対応可能な時間帯</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{ state.projectInfo.available_datetimes.map(item => item.name).join("\n") }}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>企業からのアピールポイント</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.appeal_point_from_company}}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>受験生に求めること</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.expectation_for_student}}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>地域ハブ団体からのアピールポイント</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.regional_hub_comment}}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>地域ハブ団体連絡先</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.regional_hub_contact}}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>よくあるQ&A</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.qa}}</pre>
                </div>
              </div>
              <div class="form-group">
                <h4>その他連絡事項</h4>
                <div class="form-ctrl">
                  <pre class="text-view">{{state.projectInfo.note}}</pre>
                </div>
              </div>
              <!-- for organization -->
              <div v-if="!state.isUser && state.isOwner">
                <!-- status == temp_saved -->
                <div class="form-submit" v-if="state.projectInfo.progress_status == 'temp_saved' ||
                                               state.projectInfo.progress_status == 'unapproved' ||
                                               state.projectInfo.progress_status == 'rejected' ||
                                               state.projectInfo.progress_status == 'stop'">
                  <button
                    @click="editProject()"
                    type="button"
                    :disabled="isDisabled"
                    :class="{
                      'btn-md btn-blue shadow': !isDisabled,
                      'btn-md btn-light-gray shadow': isDisabled,
                    }"
                    >
                    内容の編集
                  </button>
                </div>

                <!-- status == recruiting -->
                <div class="form-submit" v-if="state.projectInfo.progress_status == 'recruiting'">
                  <button
                    @click="confirmStop()"
                    type="button"
                    :disabled="state.isSubmittingStop && state.btnIsSubmitting == 'stop'|| isDisabled"
                    :class="{
                      'btn-md btn-blue shadow': !isDisabled,
                      'btn-md btn-light-gray shadow': isDisabled,
                    }"
                  >
                    <i
                      v-if="state.isSubmittingStop && state.btnIsSubmitting == 'stop'"
                      class="fas fa-circle-notch fa-spin"
                    ></i>プロジェクト停止
                  </button>
                  <button
                      @click="showMatches()"
                      type="button"
                      :disabled="state.isSubmittingStop && state.btnIsSubmitting == 'stop'"
                      class="btn-md btn-red shadow"
                  >希望したチーム一覧へ
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <Alert
      v-if="state.showProjectUnApprovedAlert"
      title="プロジェクト登録申請完了"
      :content="`プロジェクトの登録を申請しました。事務局の確認をお待ちください。`"
      @close="state.showProjectUnApprovedAlert = false"
    />
    <RatingPopup
      :mode="state.ratingMode"
      @close="closeRating"
      @completeProject="onCompletedRatingOrganization"
      :applicant="state.currentRatingApplicant"
      v-if="state.showRatingApplicant"
    />
    <EndRecruiting
      @reload="onCompletedEndRecruiting"
      v-if="state.showProjectEndReCruitingPopup == true"
      :projectInfo="state.projectInfo"
      @close="onCompletedEndRecruiting(true)"
    />
    <SMSPopup
      v-if="state.showSMSPopup == true"
      @close="state.showSMSPopup = false"
      @openOtp="state.showOtpPopup = true"
    />
    <OTPPopup
      v-if="state.showOtpPopup == true"
      @close="state.showOtpPopup = false"
      @confirm="confirmSMS"
    />
    <Alert
      v-if="state.showAlertPopup == true"
      :title="state.alertContent.title"
      :content="state.alertContent.content"
      @close="state.showAlertPopup = false"
    />
    <ProjectConfirmStop
      v-if="state.showConfirmStop"
      @close="closeConfirmStop"
      @accept="stopProject"
    />
    <ProjectConfirmReopen
      v-if="state.showConfirmReopen"
      @close="closeConfirmReopen"
      @accept="reopenProject"
    />
  </div>
</template>

<script lang="ts" src="@/presentation/views/organization/project-detail/information/ProjectDetailInformation.ts"></script>
<style lang="scss" src="@/presentation/views/organization/project-detail/information/ProjectDetailInformation.scss" scoped>
</style>

import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive, onMounted } from "vue";
import { useRouter } from "vue-router";
import { useForm, useField } from "vee-validate";
import { useStore } from "vuex";
import ErrorMessage from "@/presentation/components/error-message/ErrorMessage.vue";
import Textarea from "@/presentation/components/textarea/Textarea.vue";
import DIContainer from "@/core/DIContainer";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import * as yup from "yup";
import * as agh from "agh.sprintf";
export default defineComponent({
    name: "RatingPopup",
    components: {
        ErrorMessage: ErrorMessage,
        Textarea: Textarea
    },
    props: ["applicant", "mode"],
    setup: function (props, _a) {
        var emit = _a.emit;
        var store = useStore();
        var router = useRouter();
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            errorResendResetEmailMessage: null,
            errorLoginValidation: null,
            isSubmitting: false,
            projectInfo: store.state.projectInfo,
            organizationInfo: store.state.organizationMenuInfo || {}
        });
        var _b = useForm({
            initialValues: {
                rating_comment: "",
                rating: ""
            }
        }), isSubmitting = _b.isSubmitting, values = _b.values, errors = _b.errors, handleSubmit = _b.handleSubmit;
        var rating = useField("rating", yup
            .string()
            .required(state.aLang.validation_required)).value;
        var rating_comment = useField("rating_comment", yup
            .string()
            .required(state.aLang.validation_required)
            .max(1000, agh.sprintf(state.aLang.validation_max_length, 1000))).value;
        var submit = handleSubmit(function () {
            state.isSubmitting = true;
            organizationProjectInteractor.rating(Number(props.applicant.project_applicant_id), Number(values.rating), values.rating_comment).then(function (result) {
                props.applicant.is_voted = true;
                state.isSubmitting = false;
                emit('close');
            }).catch(function (error) {
                state.isSubmitting = false;
            });
        });
        var submitOrganize = handleSubmit(function () {
            state.isSubmitting = true;
            organizationProjectInteractor.ratingOrganize(Number(store.state.projectInfo.project_id), Number(values.rating), values.rating_comment).then(function (result) {
                state.isSubmitting = false;
                emit('complete-project');
                emit('close');
            }).catch(function (error) {
                state.isSubmitting = false;
            });
        });
        onMounted(function () {
            if (store.state.organizationMenuInfo) {
                state.organizationInfo = store.state.organizationMenuInfo;
            }
            store.subscribe(function (mutation, stateVuex) {
                if (mutation.type === "setOrganizationMenuInfo") {
                    state.organizationInfo = stateVuex.organizationMenuInfo;
                }
            });
        });
        return {
            state: state,
            isSubmitting: isSubmitting,
            values: values,
            errors: errors,
            props: props,
            submit: submit,
            submitOrganize: submitOrganize
        };
    }
});

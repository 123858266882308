import { defineComponent, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
export default defineComponent({
    name: "ProjectConfirmCancelApply",
    setup: function (props, _a) {
        var emit = _a.emit;
        var router = useRouter();
        var route = useRoute();
        var state = reactive({
            isSubmitting: false,
        });
        function confirm() {
            emit('confirm');
            state.isSubmitting = true;
        }
        return {
            state: state,
            confirm: confirm
        };
    }
});

<template>
    <div class="project-detail-preload__container__tab__content">
        <div class="project-detail-preload__container__tab__content_detail">
            <form class="project-detail-preload__detail">
                <div class="form-edit-group">
                    <div class="preloader"></div>
                </div>
                <div class="form-edit-group">
                    <div class="preloader"></div>
                </div>
                <div class="form-edit-group">
                    <div class="preloader"></div>
                </div>
                <div class="form-edit-group">
                    <div class="preloader"></div>
                </div>
            </form>
        </div>
        <div class="project-detail-preload__container__tab__content_qna"></div>
        <div class="project-detail-preload__container__tab__content_applicants"></div>
    </div>
    <div class="project-detail-preload__container__wrapper">
        <div class="project-detail-preload__container__wrapper__right">
            <div class="project-detail-preload__container__wrapper__right__content">
                <form>
                    <div class="form-group">
                        <h4>プロジェクト詳細</h4>
                        <div class="preloader" v-for="index in 5"></div>
                    </div>
                    <div class="form-group">
                        <h4>求める成果物</h4>
                        <div class="preloader" v-for="index in 5"></div>
                    </div>
                    <div class="form-group">
                        <h4>求める言語</h4>
                        <div class="preloader"></div>
                    </div>
                    <div class="form-group">
                        <h4>求めるスキル</h4>
                        <div class="preloader"></div>
                    </div>
                    <div class="form-group">
                        <h4>独自契約の有無</h4>
                        <div class="preloader"></div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/components/preloader/project-detail-preload/ProjectDetailPreload.ts">
</script>

<style lang="scss" src="@/presentation/components/preloader/project-detail-preload/ProjectDetailPreload.scss" scoped>
</style>

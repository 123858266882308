import LanguageUtil from "@/commons/LanguageUtil";
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";
import OrganizationProjectInteractor from "@/domain/usecases/OrganizationProjectInteractor";
import DIContainer from "@/core/DIContainer";
export default defineComponent({
    name: "ProjectConfirmEndRecruiting",
    props: [
        "projectInfo"
    ],
    setup: function (props, _a) {
        var emit = _a.emit;
        var router = useRouter();
        var state = reactive({
            aLang: LanguageUtil.getInstance().lang,
            errorResponseQuestionMessage: null,
            isSubmitting: false
        });
        var organizationProjectInteractor = DIContainer.instance.get(OrganizationProjectInteractor);
        function onSubmit() {
            state.isSubmitting = true;
            var project = {
                project_id: props.projectInfo.project_id,
                force_finish_recruiting: 1
            };
            organizationProjectInteractor.updateOrganizationProject(project.project_id, project).then(function (result) {
                emit("reload");
            }).catch(function (error) {
                state.isSubmitting = false;
            });
        }
        return {
            state: state,
            props: props,
            onSubmit: onSubmit
        };
    }
});
